import React, { lazy } from 'react';

const SubscribedProductList = lazy(() =>
  import('./SubscribedProductsList').then(module => ({
    default: module.SubscribedProductList,
  })),
);

const SubscriptionForm = lazy(() =>
  import('./SubscriptionForm').then(module => ({
    default: module.SubscriptionForm,
  })),
);

const SubscriptionDetail = lazy(() =>
  import('./SubscriptionDetail').then(module => ({
    default: module.SubscriptionDetail,
  })),
);

const SubscriptionDocument = lazy(() =>
  import('./SubscriptionDocument').then(module => ({
    default: module.SubscriptionDocument,
  })),
);

const SubscriptionLicense = lazy(() =>
  import('./SubscriptionLicense').then(module => ({
    default: module.SubscriptionLicense,
  })),
);

const SubscriptionRoutes = [
  {
    props: {
      exact: true,
      path: '/account/:accountID/productlist',
    },
    component: <SubscribedProductList />,
  },

  {
    props: { exact: true, path: '/account/:accountID/subscription/add' },
    component: <SubscriptionForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path: '/account/:accountID/subscription/:subscriptionID/edit',
    },
    component: <SubscriptionForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/account/:accountID/subscription/:subscriptionID',
    },
    component: <SubscriptionDetail />,
  },

  {
    props: {
      exact: true,
      path: '/account/:accountID/productlist/:subscriptionID/document',
    },
    component: <SubscriptionDocument />,
  },
  {
    props: {
      exact: true,
      path: '/account/:accountID/productlist/:subscriptionID/license',
    },
    component: <SubscriptionLicense />,
  },
];
export default SubscriptionRoutes;
